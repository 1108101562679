<template>
  <div>
    <b-card-actions
      ref="cardAction"
      title="Filters"
      @refresh="resetColFilters"
      @remove="resetColFilters"
    >
      <b-row>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>From date</label>
          <b-form-datepicker
            id="example-datepicker"
            v-model="dateFromFilter"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: '2-digit'}"
            locale="en"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>To date</label>
          <b-form-datepicker
            id="example-datepicker2"
            v-model="dateToFilter"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="en"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Select Status</label>
          <v-select
            v-model="statusFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Select Product</label>
          <v-select
            v-model="operator"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="operatorOption"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Enter Txnid</label>
          <b-form-input
            id="lastserverid"
            v-model="lastserverid"
            placeholder="Txind..."
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Enter Reload No</label>
          <b-form-input
            id="lastserverid"
            v-model="reload_no"
            placeholder="Reload Number.."
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2 mt-2"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="handleSearch"
          >Search
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-card-actions>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Bill Payment Report
        </h5>
      </b-card-header>
      <b-overlay
        variant="white"
        :show="showLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".28"
        rounded="sm"
      >
        <b-table-simple
          hover
          striped
          bordered
          responsive
          class="rounded-bottom mb-0"
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th>Reload No.</b-th>
              <b-th>Product</b-th>
              <b-th>[MYR]</b-th>
              <b-th>Profit</b-th>
              <b-th>User %</b-th>
              <b-th>Status</b-th>
              <b-th>Txind/Prod TxnID</b-th>
              <b-th>Reload Time/Updated Time</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-if="list.length>0">
              <b-tr
                v-for="(tr, indextr) in list"
                :key="indextr"
              >
                <b-td>
                  {{ tr.reloadno }}
                </b-td>
                <b-td>{{ tr.operator }}</b-td>
                <b-td>{{ tr.amount }}</b-td>
                <b-td>{{ tr.profit }}</b-td>
                <b-td>{{ tr.user_percentage }}</b-td>
                <b-td>
                  <b-badge :variant="getOrderStatusColor(tr.status)">
                    {{ tr.status }}
                  </b-badge>
                </b-td>
                <b-td>{{ tr.mainTxnid }}<br>{{ tr.ref_no }}</b-td>
                <b-td>{{ tr.inserteddatetime }}<br>{{ tr.updateddatetime }}</b-td>
              </b-tr>
            </template>
            <template v-else>
              <b-tr rowspan="10">
                <b-td
                  colspan="8"
                  class="text-center"
                >
                  No data avilable

                </b-td>
              </b-tr>
            </template>
          </b-tbody>

        </b-table-simple>
      </b-overlay>
      <b-card-body>
        <vue-pagination
          v-if="list.length>0"
          :pagination="userData"
          :offset="4"
          @paginate="handleChangePage"
        />
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BTableSimple,
  BCard,
  BCardHeader,
  BCardBody,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BFormDatepicker,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BBadge,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import UserReportService from '../../services/user.report.service'
import OperatorService from '../../services/operator.service'
import JwtService from '../../common/jwt.service'

export default {
  components: {
    BTableSimple,
    BFormDatepicker,
    BCard,
    BCardHeader,
    BCardBody,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    vSelect,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BBadge,
    BPagination,
    BOverlay,
    BCardActions,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dateFromFilter: null,
      dateToFilter: null,
      date: '',
      lastserverid: '',
      showLoading: false,
      reload_no: '',
      operator: '',
      statusFilter: '',
      statusOptions: [
        { label: 'All', value: '' },
        { label: 'Transfered', value: 'Transfered' },
        { label: 'Success', value: 'Success' },
        { label: 'Refunded', value: 'Refunded' },
        { label: 'InProcess', value: 'InProcess' },
        { label: 'Failed', value: 'Failed' },
      ],
      operatorOption: [],
      status: 'All',
      showMessage: false,
      message: '',
      // products: [],
      itemsPerPage: 25,
      isMounted: false,
      totalItems: 0,
      activePrompt: false,
      userData: {},
      transections: [],
    }
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    list() {
      return this.transections
    },
    queriedItems() {
      return this.totalItems
    },
    activeUserInfo() {
      const user = JwtService.getUser()
      return user
    },
  },
  created() {
    this.getOperatorList()
  },
  mounted() {
    this.getTransectionInfo({ mmtyp: 'Bill_Payment' })
  },
  methods: {
    getOrderStatusColor(status) {
      if (status === 'Success') return 'light-success'
      if (status === 'Transfered') return 'light-success'
      if (status === 'Refunded') return 'light-warning'
      if (status === 'InProcess') return 'light-warning'
      if (status === 'Failed') return 'light-danger'
      return 'success'
    },
    getOperatorList() {
      OperatorService.getAllOperater().then(
        response => {
          const self = this
          response.map((operator, key) => {
            self.operatorOption.push(
              { value: operator.id, label: operator.operator_name },
            )
          })
        },
        error => {},
      )
    },
    handleSearch() {
      if (this.dateFromFilter && this.dateToFilter) {
        this.date = `${this.dateFromFilter}:${this.dateToFilter}`
      }
      this.getTransectionInfo({
        status: this.statusFilter,
        date: this.date,
        opr: this.operator,
        lastserverid: this.lastserverid,
        reload_no: this.reload_no,
      })
    },
    handleChangePage() {
      this.getTransectionInfo({
        page: this.userData.current_page,
        status: this.statusFilter,
        date: this.date,
        opr: this.operator,
        lastserverid: this.lastserverid,
        reload_no: this.reload_no,
      })
    },
    handleSort(key, active) {},
    resetColFilters() {
      this.statusFilter = 'All'
      this.date = ''
      this.dateFromFilter = ''
      this.dateToFilter = ''
      this.getTransectionInfo()
      this.$refs.cardAction.showLoading = false
    },
    getTransectionInfo(data) {
      this.showLoading = true
      UserReportService.getAllTransactionInfo(data).then(
        response => {
          this.transections = response.Transactioninfo
          this.userData = response
          this.showLoading = false
        },
        error => {
        },
      )
    },
  },

}

</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card {
  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

.dark-layout {
  .b-overlay-wrap ::v-deep .b-overlay {
    // border: 10px solid red;
    .bg-white {
      background-color: $theme-dark-body-bg !important;
    }
  }
}
</style>
